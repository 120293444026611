import React, { FC } from 'react';
import './index.css'

const Success: FC = () => {
  return (
    <div className='successMain'>
        <h3 className='successTitle'>You have been added to the list for Touch the Sky.</h3>
        <h3 className='successTitle'>See ya in the clouds.</h3>
    </div>
  )
}

export default Success