import React, { FC } from 'react'
import './index.css';
import { Link } from 'react-router-dom';

const Hero: FC = () => {
  return (
    <div className='heroMain'>
      <h1 className='title'>TOUCH THE SKY</h1>
      <h4 className='subtitle'>Let’s go higher together.</h4>
      <h4 className='subtitle lastSubtitle'>You’re invited to a private party for the Animoca Portfolio family.</h4>
      <h6 className='caption'>Dear Irving on Hudson, NYC</h6>
      <h6 className='caption'>Thursday | April 21st | 7:30pm - 11:30pm</h6>
      <Link className='rsvpBtn' to={'/rsvp'}>RSVP</Link>
    </div>
  )
}

export default Hero