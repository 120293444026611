import axios from 'axios'
import React, { FC, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import Success from '../Success'
import './index.css'

const EmailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Form: FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
  const handleFormSubmit: SubmitHandler<FieldValues> = (formData) => {
    console.log('test data', formData)
    if (formData.email && formData.firstName && formData.lastName) {
      axios.post('https://pg-api.mithyalabs.com/api/AppModels/rsvp-for-tts', formData).then(() => setIsSubmitSuccess(true)).catch(err => console.error('test error', err))
    }
  }
  return isSubmitSuccess ? <Success /> : (
    <div className='main'>
      <h4 className='formSubtitle'>RSVP for Touch the Sky.</h4>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className='inputContainer'>
          <p className='inputTitle'>First Name*</p>
          <input {...register('firstName', { required: true })} placeholder='First name' className='inputField' />
          {errors?.firstName && <span className='formError'>First name required</span>}
        </div>
        <div className='inputContainer'>
          <p className='inputTitle'>Last Name*</p>
          <input {...register('lastName', { required: true })} placeholder='Last name' className='inputField' />
          {errors?.lastName && <span className='formError'>Last name required</span>}
        </div>
        <div className='inputContainer'>
          <p className='inputTitle'>Email Address*</p>
          <input {...register('email', { required: true, pattern: EmailRegEx })} placeholder='Email address' className='inputField' />
          {errors?.email?.type === 'required' ? <span className='formError'>Email address required</span> : errors?.email ? <span className='formError'>Email address invalid</span> : null}
        </div>
        <button type='submit' className='submitBtn'>SUBMIT</button>
      </form>
      {/* <p className='formCaption'>We respect your privacy.</p> */}
    </div>
  )
}

export default Form