import React, { FC } from 'react'
import Form from './Form';
import Hero from './Hero';
import './index.css';
import LOGOS from '../../Assets/images/touch_the_sky_logos.svg'
import { Route, Switch } from "react-router-dom";


const TouchTheSky: FC = () => {
  return (
    <div className='root'>
      <video src="./videos/touch_the_sky.mp4" className='video' autoPlay muted loop />
      <img src={LOGOS} alt="touch_the_sky_logos" className='logo' />
      <Switch>
        <Route path='/' component={Hero} exact />
        <Route path='/rsvp' component={Form} exact />
        {/* <Route path='/success' component={Success} exact /> */}
      </Switch>
    </div>
  )
}

export default TouchTheSky;