import React, { FC } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import './App.css';
import TouchTheSky from './Screeens/TouchTheSky';
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

const App: FC = () => {
  return (
    <BrowserRouter>
      <Router history={history}>
          <TouchTheSky />
      </Router>
    </BrowserRouter>
  )
}

export default App;
